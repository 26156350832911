<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <slot></slot>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      
      <b-dropdown-text v-for="(action, aci) in actionDetail.actions" :key="aci"  @click="storeAction(itemId,action.action)" tag="div" style="cursor:pointer;" class="font-weight-bold navi-item">
        <div class="navi-link">
          <!-- <i class="flaticon2-drop"></i> -->
          <span class="navi-text pl-4">
              {{action.display}}
          </span>
        </div>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import Swal from "sweetalert2";

export default {
  name: "dropdown-action",
  components: {},
  props:{
    itemId: {
      type: String,
      default: '',
    },
    actionDetail: {
      type: Object
    },
    code: {
      type: String,
      default: '',
    },
  },
  mounted() {
    // console.log("actionDetail>>> ",this.actionDetail);
  },
  methods: {
    storeAction(itemId , actionName){
      
      // console.log(" itemId >>>> ",itemId);
      // console.log(" actionName >>>> ",actionName);

    
    },
  },
};
</script>
