<template>
  <div class="topbar-item mr-10">
    
    

    <div
      class="btn  btn-sm btn-clean btn-text-dark-75"
      style="border: 1px solid #d3d3d3; padding:2px 10px;"
      :id="'kt_'.concat(id,'_toggle')"
    >
      <i  class="p-3" :class="componentDetail.iconClass" ></i>
      <span class="mitr-font ">{{componentDetail.componentMessage}}</span>
      <!-- <span class="svg-icon svg-icon-lg p-2" style="border: 1px solid #d3d3d3; " >
        <inline-svg src="/media/svg/icons/General/User.svg" />
      </span> -->
    </div>

    <div
      :id="'kt_'.concat(id)"
      :ref="'kt_'.concat(id)"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h1 class="font-weight-bold m-0">
          {{componentDetail.componentMessage}}
        </h1>
         <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          :id="'kt_'.concat(id,'_close')"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
          <div v-for="(cc, cc_ind) in componentDetail.content" v-bind:key="cc_ind" >
            <div  >
              <Form1  :formDetail="cc.detail"></Form1>

            </div>
          </div>
        
        </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_sidebar_import {
  overflow: hidden;
}
</style>

<script>
// import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutSidebarImport from "@/assets/js/layout/extended/sidebar-import.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

import Form1 from "@/view/alex/eCouponForm1.vue";

export default {
  name: "SidebarImport",
  props:  {
    componentDetail:{
      type: Object
    },
   
  },
  data() {
    return {
      overlay: false,
      id: "",
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning"
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success"
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger"
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info"
        }
      ]
    };
  },
  created() {

  },
  mounted() {
    // Init Quick User Panel
    this.id = this.componentDetail.componentId;
    console.log("componentDetail >>> ", this.componentDetail);

    let theId = "kt_".concat(this.id);

    console.log("theId >>> ",theId , this.id);

    this.$nextTick(() => {
        KTLayoutSidebarImport.init(this.$refs[theId],this.id);

      });

  },
  methods: {
    onLogout() {
      this.$store
        .dispatch("auth/LOGOUT")
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutSidebarImport.getElement()).hide();
    }
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "/media/users/300_21.jpg";
    }
  },
  components:{
    Form1
  }
};
</script>


