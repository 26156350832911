//*** Form1 V7 13June2022 */
<template>
  <div class="card card-custom">
    <div class="blockui"></div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
       class="d-flex justify-content-center"
      >
        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 col-lg-8 col-sm-12 col-xs-12 cols-8" style="width: 100%" :class="formDetail.formClass" >
          <!--begin: Wizard Form-->
          <div class=" justify-center " style="width:100%;">
            <div class="">
              <div class="form" id="kt_form">
               

                <!--begin: ข้อมูลหน้าร้านของลูกค้า -->
                  <h2 class="card-label font-weight-bolder text-dark text-3xl mitr-font pb-12">
                    {{formDetail.formHeader}}
                  </h2>


                 <div class="row">
                   <div v-for="(formParam, fi) in formDetail.formGroup"  v-bind:key="fi" class="mitr-font" :class="columnWidth('col-xl-', formParam['width'] )" >
                          

                       <!-- REVIEW: text label -->
                        <div v-if="formParam['type'] === 'review'"  >
                          <span class="pr-2"  style="text-align:end; 
                           font-size: 1.2rem;  display: inline-block;"  >{{formParam['label']}} : </span>
                          <span class=" mitr-font; px-3 pb-4"  style="font-size: 1.2rem;  display: inline-block; color: rgb(35, 114, 206); " :style="formParam['style']" >
                            {{$data['form1'][fi]}}
                          </span>
                          
                        </div> 
                        <!-- REVIEW2: text label -->
                        <div v-if="formParam['type'] === 'review2'" class=" align-items-center" style="height: fit-content;" >
                          <span class="pr-2 pt-6"  style="text-align:end;  font-size: 1.6rem;  display: inline-block;"  >{{formParam['label']}} : </span>
                          <span class=" mitr-font; px-3 pt-6"  style=" text-align:end; font-size: 1.6rem;  display: inline-block; color: rgb(35, 114, 206); " :style="formParam['style']" >
                            {{$data['form1'][fi]}}
                          </span>
                          
                        </div> 
                        <!-- REVIEW2: text label  -->


                        <!-- REVIEW3: text label -->
                        <div v-if="formParam['type'] === 'review3'" class=" align-items-center" style="height: fit-content;" >
                          <span class="pr-2 pt-6"  style="text-align:end;  font-size: 1.2rem;  display: inline-block;"  >{{formParam['label']}} : </span>
                          <span  v-for="(tagItem, tagi) in $data['form1'][fi]" v-bind:key="tagi" class=" mitr-font; px-2 pt-6"  style=" text-align:end; font-size: 1rem;  display: inline-block; color: rgb(35, 114, 206); " :style="formParam['style']" >
                            {{tagItem}}
                          </span>
                          
                        </div> 
                        <!-- REVIEW3: text label  -->

                           <!-- Just line -->
                          <div v-if="formParam['type'] === 'line'" style="border-bottom: 1px solid #d3d3d3;"  :style="formParam['style']" :class="formParam['class']">
                           
                          </div> 
                        <!-- Just line -->

                          <!-- TAG: input tag -->
                          <div v-if="formParam['type'] === 'tag'"  class="form-group" :style="formParam['style']">
                            <label for="tags-basic">{{formParam['label']}}</label>
                            <b-form-tags  v-model="$data['form1'][fi]"></b-form-tags>
                            <!-- <p class="mt-2">Value: {{ value }}</p> -->
                          </div>
                          <!-- TAG: input tag -->

                          <!-- TEXT: input text -->
                          <div v-if="formParam['type'] === 'text' || formParam['type'] === 'email'"  class="form-group" :style="formParam['style']">
                            <label>{{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span></label>
                            <b-form-input
                              :type="formParam['type']"
                              :name="formParam['param']"
                              :placeholder="formParam['placeholder']" 
                              v-model="$data['form1'][fi]"
                              :state="validation[fi]"
                              aria-describedby="input-shopName-help"
                              debounce="500"
                              @change="validate_text(fi ,formParam,$data['form1'][fi])"
                            />
                            <span v-if="formParam['subText']"
                              class="text-muted font-weight-bold text-muted d-block pt-3"
                              >
                                  {{item[formParam['subText']]}}
                            </span>

                             <span v-if="formParam['bluePrefix'] && formParam['blueText1']==='length'"
                              class="text-muted font-weight-bold text-muted pr-3 pt-3"
                              style="color: #2372ce !important; text-weight: bold;"
                              >
                                  {{formParam['bluePrefix']}} {{smsTextLength($data['form1'][fi])}} 
                            </span>

                            <span v-if="formParam['redPrefix'] && formParam['redText1']==='credit'"
                              class="text-muted font-weight-bold text-muted  pt-3"
                              style="color: #f64e60 !important; text-weight: bold;"
                              >
                                  {{formParam['redPrefix']}} {{calculateSmsCredit($data['form1'][fi])}} 
                            </span>
                            
                            <b-form-invalid-feedback id="input-shopName-help">
                              {{warning[fi]}} 
                            </b-form-invalid-feedback>
                        </div> 
                        <!-- TEXT: input text -->


                        <!-- PASSWORD: password -->
                          <div v-if="formParam['type'] === 'password'"  class="form-group" :style="formParam['style']" >

                            <label>{{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span></label>
                            <b-form-input
                              :type="formParam['type']"
                              :name="formParam['param']"
                              :placeholder="formParam['placeholder']" 
                              v-model="$data['form1'][fi]"
                              :state="validation[fi]"
                              aria-describedby="input-shopName-help"
                              debounce="500"
                              @change="validate_text(fi ,formParam,$data['form1'][fi])"
                            />
                            <b-form-invalid-feedback id="input-shopName-help">
                              {{warning[fi]}} 
                            </b-form-invalid-feedback>
                        </div> 
                        <!-- PASSWORD: password -->

                        <!-- NUMBER: input number -->
                          <div v-if="formParam['type'] === 'number'"  class="form-group" :style="formParam['style']" >
                            <label>{{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span></label>
                            <b-form-input
                              :type="formParam['type']"
                              :name="formParam['param']"
                              :placeholder="formParam['placeholder']" 
                              :state="validation[fi]"
                              v-model="$data['form1'][fi]"
                              v-on:focus="selectAll"
                              aria-describedby="input-shopName-help"
                              @change="validate_number(fi, formParam ,$data['form1'][fi])"

                            />
                            <b-form-invalid-feedback :id="formParam['param']">
                              กรุณาใส่ข้อมูล 
                            </b-form-invalid-feedback>
                        </div> 
                        <!-- input number -->


                        <!-- text area -->
                          <div v-if="formParam['type'] === 'textarea'"  class="form-group" :style="formParam['style']">
                            <label>{{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span></label>
                            <b-form-textarea
                              :type="formParam['type']"
                              :name="formParam['param']"
                              rows="6"
                              max-rows="6"
                              :placeholder="formParam['placeholder']" 
                              v-model="$data['form1'][fi]"
                              :state="validation[fi]"
                              :aria-describedby="formParam['param']"
                              debounce="500"
                              :disabled="formParam['disabled']"
                              @change="validate_text(fi ,formParam,$data['form1'][fi])"
                            />
                            <b-form-invalid-feedback :id="formParam['param']">
                              กรุณาใส่ข้อมูล 
                            </b-form-invalid-feedback>
                        </div> 
                        <!-- text area -->
                      

                        <!-- select -->
                        <div v-if="formParam['type'] === 'select'" class="form-group" :style="formParam['style']">
                          <label>{{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span></label>
                          <b-form-select
                            :name="formParam['param']"
                            
                            v-model="$data['form1'][fi]"
                            @change="validate_select(fi, formParam['required'] ,$data['form1'][fi])"
                           :state="validation[fi]"
                           debounce="500"
                           :aria-describedby="formParam['param']"
                          >
                             <option v-for="(optionItem, opi) in formParam['option']" v-bind:key="opi" :value="optionItem['value']">{{optionItem["display"]}}</option>
                          </b-form-select>
                          <b-form-invalid-feedback :id="formParam['param']">
                              กรุณาเลือกข้อมูล
                          </b-form-invalid-feedback>
                        </div> 
                        <!--select -->


                       

                        <!-- datetime -->
                          <div v-if="formParam['type'] === 'datetime'"  class="form-group" :style="formParam['style']">
                            <label>{{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span></label>
                           <b-form-datepicker

                              today-button
                              reset-button
                              close-button
                              locale="th"

                              :name="formParam['param']"
                              v-model="$data['form1'][fi]"
                              :state="validation[fi]"
                              :aria-describedby="formParam['param']"
                              debounce="500"
                              @blur="validate_date(fi, formParam['required'] ,$data['form1'][fi])"
                            />
                            <b-form-invalid-feedback :id="formParam['param']">
                              กรุณาเลือกวันเดือนปี
                            </b-form-invalid-feedback>
                        </div> 
                        <!-- datetime -->


                        <!-- file -->
                          <div v-if="formParam['type'] === 'file'"  class="form-group" :style="formParam['style']">
                            
                            <label>{{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span></label>
                           
                              <b-form-file
                                :name="formParam['param']"
                                v-model="$data['form1'][fi]"
                                :state="validation[fi]"
                                :aria-describedby="formParam['param']"
                                :ref="'uploadImgInput' + fi.toString()"
                                :id="'uploadImgInput' + fi.toString()"
                                debounce="500"
                                 @change="updateCurrImg(fi)"
                             ></b-form-file>
                          
                            <b-form-invalid-feedback :id="formParam['param']">
                              กรุณาเลือกไฟล์
                            </b-form-invalid-feedback>
                        </div> 
                        <!-- file -->

                        <!-- upload file default image -->
                         <div v-if="formParam['type'] === 'uploadDefaultImage'"  class="form-group" :style="formParam['style']" >
                           <div class="col-xl-12 mitr-font" style="padding-right:0;">
                                        {{formParam['label']}} <span v-if="formParam['required']===true" style="color:red;" >*</span>
                            </div>
                            <template v-if="$data['form1'][fi]">
                                  
                                  <!-- Image Container -->
                                  <div class="img-container w-64 mx-auto flex items-center justify-start">
                                    <div class="row">
                                      
                                      <div class="col-xl-12" style="padding-right:0;">
                                      <i
                                            @click="removeImage(fi)"
                                            style="float:right; font-size:2rem; vertical-align:middle; color:red;  cursor:pointer;"
                                            class="material-icons"
                                          >delete_forever</i>
                                      </div>
                                    <img :src="getFullPath($data['form1'][fi])" alt="img" class="responsive" />
                                    <!-- $data['form1'][fi] -->
                                  </div>
                                  
                                  </div>

                                  

                                  <!-- Image upload Buttons -->
                                  <div class="modify-img flex justify-between mt-5">
                                    <input
                                      type="file"
                                      class="hidden"
                                      :ref="'uploadImgInput' + fi.toString()"
                                      :id="'uploadImgInput' + fi.toString()"
                                      @change="updateCurrImg(fi)"
                                      accept="image/*"
                                    />

                                  </div>
                            </template>

                                <div class="upload-img mt-5 d-flex justify-start" v-if="!$data['form1'][fi]">
                                   
                                  <input
                                    type="file"
                                    class="hidden"
                                    :ref="'uploadImgInput' + fi.toString()"
                                    :id="'uploadImgInput' + fi.toString()"
                                    @change="updateCurrImg(fi)"
                                    accept="image/*"
                                  />
                                   <!-- <div class="btn btn-info" :class="concatText('btn-', formParam['buttonColor'] )" @click="$refs['uploadImgInput' + fi.toString()][0].click()" >{{formParam['label']}}</div> -->
                                   <div style="cursor:pointer; width:250px; " :class="concatText('btn-', formParam['buttonColor'] )" @click="$refs['uploadImgInput' + fi.toString()][0].click()" >
                                   <img src="/media/0mine/defaultImage.png" alt="img" class="responsive" />
                                   </div>
                                </div>


                          </div>
                        <!-- upload file default image -->


                         <!-- upload file button -->
                         <div v-if="formParam['type'] === 'uploadbutton'"  class="form-group">
                            <template v-if="$data['form1'][fi]">
                                  <!-- Image Container -->
                                  <div class="img-container w-64 mx-auto flex items-center justify-start">
                                    <div class="row">
                                      <div class="col-xl-12" style="padding-right:0;">
                                      <i
                                            @click="removeImage(fi)"
                                            style="float:right; font-size:2rem; vertical-align:middle; color:red;  cursor:pointer;"
                                            class="material-icons"
                                          >delete_forever</i>
                                      </div>
                                    <img :src="getFullPath($data['form1'][fi])" alt="img" class="responsive" />
                                    <!-- $data['form1'][fi] -->
                                  </div>
                                  
                                  </div>

                                  

                                  <!-- Image upload Buttons -->
                                  <div class="modify-img flex justify-between mt-5">
                                    <input
                                      type="file"
                                      class="hidden"
                                      :ref="'uploadImgInput' + fi.toString()"
                                      :id="'uploadImgInput' + fi.toString()"
                                      @change="updateCurrImg(fi)"
                                      accept="image/*"
                                    />

                                  </div>
                            </template>

                                <div class="upload-img mt-5 d-flex justify-center" v-if="!$data['form1'][fi]">
                                  <input
                                    type="file"
                                    class="hidden"
                                    :ref="'uploadImgInput' + fi.toString()"
                                    :id="'uploadImgInput' + fi.toString()"
                                    @change="updateCurrImg(fi)"
                                    accept="image/*"
                                  />
                                   <div class="btn btn-info" :class="concatText('btn-', formParam['buttonColor'] )" @click="$refs['uploadImgInput' + fi.toString()][0].click()" >{{formParam['label']}}</div>
                                   <!-- <div style="width:200px; height:200px; background-color: blue;" :class="concatText('btn-', formParam['buttonColor'] )" @click="$refs['uploadImgInput' + fi.toString()][0].click()" ></div> -->
                                </div>


                          </div>
                        <!-- upload file button -->

                           
                    </div> <!-- vfor -->
                  </div> <!-- row -->

            
                 
               


                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-end pt-10">
                  <!-- <div class="mr-2">
                    <div
                    v-if="myWizardStep === 2 || myWizardStep === 3"
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                      v-on:click="previousStep"
                    >
                      Previous
                    </div>
                  </div> -->
                  <div  v-for="(formBtn, bi) in formDetail.formButton"  v-bind:key="bi" >
                   <b-button
                      v-if="formBtn.type === 'storeActionAsync'"
                      v-on:click="storeAction($event,formBtn)"
                      :variant="concatText('outline-',formBtn.color)"
                      class="btn  text-uppercase px-9 py-4 ml-8"
                      style="font-family: mitr; font-weight: bold; font-size: 1.1rem;"
                      data-wizard-type="action-submit"
                      :style="formBtn.style"
                      :disabled="buttonLock"
                    >
                      {{formBtn.label}}
                    </b-button>
                    <b-button
                      v-if="formBtn.type === 'submit' || formBtn.type === 'uploadFile'"
                      v-on:click="submitData($event,formBtn)"
                      :variant="concatText('outline-',formBtn.color)"
                      class="btn  text-uppercase px-9 py-4 ml-8"
                      style="font-family: mitr; font-weight: bold; font-size: 1.1rem;"
                      data-wizard-type="action-submit"
                      :style="formBtn.style"
                      :disabled="buttonLock"
                    >
                      {{formBtn.label}}
                    </b-button>
                    <b-button
                      v-if="formBtn.type === 'clear'"
                      v-on:click="clearData($event)"
                      :variant="concatText('outline-',formBtn.color)"
                      class="btn  text-uppercase px-9 py-4 ml-8"
                      style="font-family: mitr; font-weight: bold; font-size: 1.1rem;"
                      data-wizard-type="action-submit"
                      :style="formBtn.style"
                    >
                      {{formBtn.label}}
                    </b-button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </div>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import KTLayoutSidebarImport from "@/assets/js/layout/extended/sidebar-import.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
// import axios from "axios";

import shapeFormat from '@/mixins/shapeFormat.js'

import Vue from 'vue'

export default {
  name: "Form1",
  mixins: [shapeFormat],
  props: {
    formDetail:{
      type: Object
    }
  },
  data() {
    return {
      allFormData: null,
      form1: [],
      validation: [],
      warning: [],

    }
  },

 

  async created() {

    this.allFormData = new FormData();

    //  console.log("formDetail >>>", formDetail);


    // this.formDetail.formGroup.forEach((item , ind) => {

    //   //*** INITIAL VALIDATION */
    //   this.validation[ind] = null;
    //   this.warning[ind] = null;

    //    //*** INITIAL VARIABLE each  */

    
      
    // });
  },

  computed: {
     formData() {
      //  console.log("FORM1 computed $$$$$$$$$$$$$$$$$$$ ");
       return this.$store.getters[ this.formDetail.formStore + "/formData"];
     },
     buttonLock(){
       return this.$store.getters[ "common/buttonLock"];
     },
   },
   components:{
   },
   watch:{
     formData: {
       handler(value){
        //  this.mappingForDataToForm1(id);
        //  console.log("SHOW WATCHHHHHHH  this.formDetail.formStore >>>> ", this.formDetail.formStore);
         
        //  console.log("SHOW WATCHHHHHHH formData >>>> ",value);
            this.formDetail.formGroup.forEach((item , ind) => {
                    Vue.set(this.$data["form1"] , ind , this.formData[item.param]);
                });
       },
       deep: true
     }
    //  form1:{
    //    handler(){
    //      console.log(this.$data['form1']);
    //    }
    //    ,
    //    deep: true
    //  }
     
   },
  mounted() {
        let id = this.formDetail.formDataId;
        // console.log("this.formDetail.formDataId >>> ",id);
        if(this.formDetail.formType === "update"){
          this.mappingForDataToForm1(id);
        }else{
          this.init();
        }
        // console.log("MOUNTED formDetail >>>", formDetail);

    this.init();
  },

  methods: {
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutSidebarImport.getElement()).hide();
    },

    init(){

          this.allFormData = new FormData();

          this.formDetail.formGroup.forEach((item , ind) => {

          //*** INITIAL VALIDATION */
          this.validation[ind] = null;
          this.warning[ind] = null;

          //*** INITIAL VARIABLE each  */

          if(item["type"] === "text"){
            this.form1[ind]  = null;
          }else if(item["type"] === "tag"){
              this.form1[ind] = [];
          }else if(item["type"] === "number"){
            this.form1[ind] = 0;
          }else if(item["type"] === "datetime"){
            this.form1[ind] = null;
          }else if (item["type"] === "select"){
            this.form1[ind] = null;
          }
          else if (item["type"] === "uploadbutton"){
            this.form1[ind] = null;
          }
          else if (item["type"] === "uploadDefaultImage"){
            this.form1[ind] = null;
          }
          else if (item["type"] === "textarea"){
            this.form1[ind] = null;
          } else if (item["type"] === "file"){
            this.form1[ind] = null;
          }

          else if (item["type"] === "hidden"){
            this.form1[ind] = null;
          }
          
          
          else{
            this.form1[ind] = null;
          }

          // this.mappingForDataToForm1(ind);
      });
    },


    clearData(e){

          e.preventDefault();

          this.allFormData = new FormData();

          this.formDetail.formGroup.forEach((item , ind) => {

          //*** INITIAL VALIDATION */
          this.validation[ind] = null;
          this.warning[ind] = null;

          //*** INITIAL VARIABLE each  */

          if(item["type"] === "text"){
            // this.form1[ind]  = null;
            Vue.set(this.$data.form1, ind, null);

          }else if(item["type"] === "number"){
            // this.form1[ind] = 0;
            Vue.set(this.$data.form1, ind, 0);

          }else if(item["type"] === "datetime"){
            
            // this.form1[ind] = null;
            Vue.set(this.$data.form1, ind, null);


          }else if (item["type"] === "select"){
            // this.form1[ind] = null;
            Vue.set(this.$data.form1, ind, null);
          }
          else if (item["type"] === "uploadbutton"){
            // this.form1[ind] = null;
            Vue.set(this.$data.form1, ind, null);
          }
          else if (item["type"] === "uploadDefaultImage"){
            this.form1[ind] = null;
          }
          else if (item["type"] === "textarea"){
            // this.form1[ind] = null;
            Vue.set(this.$data.form1, ind, null);
          } else if (item["type"] === "file"){
            // this.form1[ind] = null;
            Vue.set(this.$data.form1, ind, null);
          }
          
          else if (item["type"] === "hidden"){
            // this.form1[ind] = null;
            Vue.set(this.$data.form1, ind, null);
          }
          
          else{
            // this.form1[ind] = null;
            Vue.set(this.$data.form1, ind, null);
          }

      });
    },

 

    async mappingForDataToForm1(id){
      if(this.formDetail.formType === "update"){

              console.log("UmappingForDataToForm1 id >>> a",id);

              let param = {};
              param.apiUrl = this.formDetail.formApiUrl;
              param.params = id;

            await this.$store.dispatch(this.formDetail.formStore + "/loadFormData" , param);

            console.log("mappingForDataToForm1 UPDATE CASE formdata", this.formData);

            this.formDetail.formGroup.forEach((item , ind) => {
                this.$data["form1"][ind]  =  this.formData[item.param];
                Vue.set(this.$data["form1"] , ind , this.formData[item.param]);
            });
        }
    },
    async storeAction(e, formBtn){
      e.preventDefault();
      // console.log("store actin formBtn >>> ",formBtn);
      let param = formBtn;
      await this.$store.dispatch(formBtn.storeAction, param);
    },

     async submitData(e, formBtn) {
      e.preventDefault();
      // this.$loading.show();
      var checkState = true;

      let httpRequest = {};

      let startDate = null;
      let endDate = null;
      let startDateTime = null;
      let endDateTime = null;

      this.formDetail.formGroup.forEach((formParam , fi) => {
            //*** INITIAL VALIDATION */
            // this.validation[ind] = null;

            //*** HANDLE HIDDEN BEGIN: */

            let prmArr = [];
           

            if(formParam["type"] === "hiddenText" || formParam["type"] === "hiddenNumber" ){
              
              prmArr =  this.extractValue(this.$data['form1'], this.formDetail.formGroup , formParam["functParam"]);
              console.log("formParam >>> ",formParam);
              console.log("prmArr >>> ",prmArr);

              if(formParam["registry"] === "shapeFormat")
              {
                Vue.set( this.$data['form1'], fi , this[formParam["funct"]](prmArr)  );
              }

            }


            //*** HANDLE HIDDEN END: */


            //*** INITIAL VARIABLE each  */

            if(formParam["type"] === "text" || formParam["type"] === "email" || formParam["type"] === "hiddenText"){
              // 
              this.validate_text(fi ,formParam, this.$data['form1'][fi]);
              
              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];

            
            }
            else if(formParam["type"] === "tag" ){
              
              this.validate_number(fi ,formParam , this.$data['form1'][fi]);
              
              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];
            }
            else if(formParam["type"] === "number" || formParam["type"] === "hiddenNumber"){
              
              this.validate_number(fi ,formParam , this.$data['form1'][fi]);
              
              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];
            }
            else if(formParam["type"] === "textarea"){
              
              this.validate_text(fi ,formParam, this.$data['form1'][fi]);
              
              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];
            
            }
            else if(formParam["type"] === "datetime"){

              this.validate_date(fi, formParam['required'] ,this.$data['form1'][fi]);
              
              //*** Mapping data
              httpRequest[formParam["param"]] = this.formatDate(this.$data['form1'][fi]);

            }else if (formParam["type"] === "select"){

              this.validate_select(fi, formParam['required'] ,this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];

            }else if (formParam["type"] === "uploadbutton" || formParam["type"] === "uploadDefaultImage"){
              this.validate_uploadbutton(fi, formParam['required'] ,this.$data['form1'][fi]);
              
              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];

            }
            
            
            else{

                this.validate_text(fi ,formParam, this.$data['form1'][fi]);

                httpRequest[formParam["param"]] = this.$data['form1'][fi];
            }

            //*** MAPPING DATA TO API */

           

            if ( formParam["param"] === "startDate" ) startDate = this.$data['form1'][fi];
            if ( formParam["param"] === "endDate" ) endDate = this.$data['form1'][fi];
            if ( formParam["param"] === "startDateTime" ) startDateTime = this.$data['form1'][fi];
            if ( formParam["param"] === "endDateTime" ) endDateTime = this.$data['form1'][fi];


      });
       
      

      console.log("httpRequest >>> ",httpRequest);

      if ( startDate !== null && endDate !== null ) {
        if (this.formatDate(startDate) > this.formatDate(endDate)) {
          Swal.fire({
              title: "",
              text: `วันสิ้นสุดต้องไม่เกินวันเริ่มต้น`,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary"
            });

          return;
        }
      }

      if ( startDateTime !== null && endDateTime !== null ) {
        if (this.formatDate(startDateTime) > this.formatDate(endDateTime)) {
          Swal.fire({
              title: "",
              text: `วันสิ้นสุดต้องไม่เกินวันเริ่มต้น`,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary"
            });

          return;
        }
      }

      this.validation.forEach( item =>
        {
          // console.log("validation item >>> ",item);
          if(item === false) checkState = false;
          // checkState = checkState && item
        }
      );


      // console.log("CHECK STATE >>> ",checkState);

      
      

   

      this.$store.commit("common/OVERLAY",true);
  

      if(checkState === false){
          this.disableBtn = false;
          // Swal.fire({
          //   title: "",
          //   text: "ยังใส่ข้อมูลไม่ครบถ้วน",
          //   icon: "warning",
          //   confirmButtonClass: "btn btn-secondary"
          // });
          this.$bvToast.toast(`กรุณากรอกข้อมูลให้ครบถ้วน `, {
            title: `ยังใส่ข้อมูลไม่ครบ`,
            toaster: "b-toaster-top-center",
            solid: true,
            variant: "warning",
            appendToast: false
          });

          httpRequest = {};
          
          return;
      }

      this.disableBtn = true;

      this.$store.commit("common/BUTTON_LOCK");

      let data = {};
      data.apiUrl = formBtn.apiUrl;
      data.params = httpRequest;
      data.formDetail = formBtn;

      // console.log("MAPPING DATA TO API BEFORE SEND TO STORE >>> ",httpRequest);

      if(formBtn.type === "submit"){
         if(this.formDetail.formType === "update"){
            data.dataId = this.formDetail.formDataId;
            await this.$store.dispatch(formBtn.storeAction, data);
         }else{
           //*** สร้างใหม่ 
            await this.$store.dispatch(formBtn.storeAction, data);
            this.form1 = [];
            this.validation = [];
            this.warning= [];
         }

         

      }
      else if(formBtn.type === "uploadFile"){
        await this.uploadFileFunc(formBtn)
      }
      
      // this.$store.commit("common/OVERLAY",false);

    //  this.$loading.hide(); 

      
    },

    concatText(text1 , text2){
      return text1 + text2;
    },

    async uploadFileFunc(formBtn){
          var submitRes = null;
          let res = false;
          // let resText = "hello";

          // console.log("Upload file formBtn >>> ",formBtn);
            let resText = "";


          try {

            // console.log("formBtn.apiUrl >>> ",formBtn.apiUrl);

            submitRes = await this.$http.post(
              this.$store.state.common.baseUrl + formBtn.apiUrl ,
              this.allFormData,
              this.$store.state.auth.apiHeader
            );

            // console.log("this.$store.getters['coupon/pageData'] >>> " , this.$store.getters['coupon/pageData']);


            // let param = {
            //   apiUrl : formBtn.apiUrl,
            //   data : this.allFormData
            // }


            // let item = {list:  submitRes.data.result, transform:  state.transform};
            // res = await this.$store.dispatch(formBtn.storeAction, submitRes.data.result);
            //************ BEGIN ********************** */
            resText = await this.$store.dispatch(formBtn.storeAction, submitRes.data);
            //************* END ********************* */


            // console.log("resText " , resText);
            
            let responseMsg = "";
            let resIcon = "success";

            if(submitRes.data.success === false)
            {
              responseMsg = submitRes.data.msg;
              resIcon = "warning"
            }

            // let result = await this.$store.dispatch(formBtn.storeAction, param);

            
            Swal.fire({
              title: responseMsg,
              text: resText,
              icon: resIcon,
              confirmButtonClass: "btn btn-secondary"
            });

            this.closeOffcanvas();
            this.form1 = [];


          } catch (err) {
            Swal.fire({
              title: "",
              text: "ไม่สามารถอัพโหลดไฟล์ได้",
              icon: "warning",
              confirmButtonClass: "btn btn-secondary"
            });
            this.$store.commit("common/BUTTON_UNLOCK");

            // console.log("UPLOAD FAILED ")
          }

          // if(res === true)
          // {
            // this.clearData();

           
            this.$store.commit("common/BUTTON_UNLOCK");
            // this.form1 = [];
          // }

          if (formBtn.nextAction === "callasync") {
            let params = {
              apiUrl: formBtn.callApi,
              params: formBtn.callParam,
            };
           
            await this.$store.dispatch(formBtn.callAction,params);
          }

          // Vue.set(this.$data['form1'], 0 ,null)

          // console.log("after submitRes this.$data.form1 ",this.$data.form1);
    },

 

     updateCurrImg( fi) {


      // console.log("this.$refs >>> ",this.$refs)

      const selectedFile = document.getElementById('uploadImgInput' + fi.toString());

      // console.log("selectedFile.files[0] >>> ",selectedFile.files[0]);


      // console.log("BEFORE this.$data['form1'][fi] >>> ",this.$data['form1'][fi]);

      

      if (selectedFile.files && selectedFile.files[0]) {


        // this.$data['form1'][fi] = selectedFile.files[0];

         

        var reader = new FileReader();
        reader.onload = e => {
          // this.$data['form1'][fi] = e.target.result;
          e.preventDefault();

          let maxImageSize  = this.$store.getters["common/maxImageSize"];

          if (selectedFile.files[0].size > maxImageSize * 1024 * 1024) {
            // alert('File too big (> 1MB)');
            Swal.fire({
              title: "",
              text: `ขนาดไฟล์รูปภาพต้องมีขนาดไม่เกิน ${maxImageSize} MB`,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary"
            });
            return;
          }

          Vue.set( this.$data['form1'], fi ,e.target.result );
          // console.log("UNDER IF this.$data['form1'][fi] >>> ",this.$data['form1'][fi]);


        };
        reader.readAsDataURL(selectedFile.files[0]);

        const formData = new FormData();
        formData.append("uploadFile", selectedFile.files[0]);

        this.allFormData.append("fileData", selectedFile.files[0]);



        // if (!file) {
        //   e.preventDefault();
        //   alert('No file chosen');
        //   return;
        // }
        
       


        // console.log("formData >>> ",formData);

        // var submitRes = null;

        // try {
        //   submitRes = await axios.post(
        //     this.$store.common.state.apiURL + "/ecoupon-api/slip-upload/" + this.orderHeaderId,
        //     formData,
        //     this.$store.state.auth.apiHeader
        //   );
        // } catch (err) {
        //   submitRes = null;
        // }

       

        // if (submitRes !== null) {
        //   await this.reload();
        //    //  *** BEGIN : 2020-11-28 : Search By branches
        //   this.searchBtn();
        //   //  *** END : 2020-11-28 : Search By branches
        //   await this.reloadImage();
        //   this.dataImg = null;
        //   setTimeout(() => {
        //     this.$vs.loading.close();
        //     this.noticeSuccess("file uploaded !!!");
        //   }, 1000);
        // } else {
        //   this.noticeError("Something went wrong");
        // }
      }
    },

    async removeImage(fi) {
      
      // this.$data['form1'][fi] = null;

      Vue.set( this.$data['form1'], fi , null );

      // console.log(this.$data['form1']);
      
      // var res = null;

      // try {
      //   res = await axios.delete(
      //     this.$store.state.apiURL + "/ecoupon-api/slip/" + imgid,
      //     this.$store.state.auth.apiHeader
      //   );
      // } catch (err) {
      //   res = null;
      // }
      // if (res !== null) {
      //   this.reloadImage();
      //   setTimeout(() => {
      //     this.$vs.loading.close();
      //     this.noticeSuccess("ลบสำเร็จ");
      //   }, 1000);
      // } else {
      //   this.noticeError("ลบไม่สำเร็จ");
      // }
    },


    selectAll: function(event) {
      // Workaround for Safari bug
      // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(function() {
        event.target.select();
      }, 0);
    },
    columnWidth(text, param){
      if(param === "half"){
        return text + "6"
      }else{
        return text + "12"
      }
    },

    trialOrProduction(statusInfo){
      if(statusInfo === 'production'){
        return "ใช้จริงเลย"
      }else if(statusInfo === 'trial'){
        return "ทดลองก่อน"
      }else{
        return "ไม่ระบุ"
      }
    },
    payment(paid){
      if(paid === true)
      {
        return "ชำระแล้ว"
      }else{
        return "ยังไม่ชำระ"
      }
    },
    nextStep(){
        
        // console.log("wizard.getStep() >>> ",this.myWizard.getStep());
        if(this.myWizard.getStep() === 1){
          this.usernameCheck();
          this.passwordCheck();
          if(this.usernameState === true && this.passwordState === true){
            this.myWizard.goNext();
            this.myWizardStep++;
          }
        }
        else if(this.myWizard.getStep() === 2){
          this.shopNameStateCheck();
          // this.shopPhoneStateCheck();
          // this.shopLineStateCheck();
          this.shopPackageStateCheck();
          this.shopCategoryStateCheck();
          this.shopStatusInfoStateCheck();
          if(this.shopNameState === true && this.shopPackageState === true && this.shopCategoryState === true && this.shopStatusInfoState === true){
            this.myWizard.goNext();
            this.myWizardStep++;
          }
        }

    },
    previousStep(){
      this.myWizard.goPrev();
      this.myWizardStep--;
    },
   
    validate_date(ind, req,  date){

      // console.log("validate_date >>> ",ind , " req: ", req , " date : ", date  )
      if(  (date === null || date === undefined || date === "")   && req === true  )
      {
        Vue.set(this.validation, ind , false);
        return ;
      }

      if(  (date === null || date === undefined || date === "")  && (req === false || req === null || req === undefined) )
      {
        Vue.set(this.validation, ind , null);
        return ;
      }

      if(date !== null && date !== "" && date !== undefined){
          Vue.set(this.validation, ind , true)
          return ;
      }
    },


    validate_number(ind, form,  item){

      // console.log("validate_number>>> ",ind , " form: ", form , " item : ", item  );
      // console.log("this.$data['form1'] >>> ",this.$data['form1'] );

      if(  (item === null || item === undefined || item === "")   && form["required"] === true  )
      {
        // console.log("--------- 1 ------------- ");

        Vue.set(this.validation, ind , false);
        return ;
      }

      if(  (item === null || item === undefined || item === "")  && (form["required"] === false || form["required"] === null || form["required"] === undefined) )
      {
        // console.log("--------- 2 ------------- ");
        Vue.set(this.validation, ind , null);
        return ;
      }

      if(form["positiveOnly"] === true && item < 0 ){
        // console.log("--------- 4 ------------- ");
         Vue.set(this.$data['form1'] , ind , 0);
         console.log("positiveOnly >>> " )
         Vue.set(this.validation, ind , false);
         return ;
      }


      if(item !== null && item !== "" && item !== undefined){
        // console.log("--------- 3 ------------- ");
          Vue.set(this.validation, ind , true)
          return ;
      }

      
    },

    validate_uploadbutton(ind, req,  upload){

      // console.log("validate_date >>> ",ind , " req: ", req , " date : ", date  )
      if(  (upload === null || upload === undefined || upload === "")   && req === true  )
      {
        Vue.set(this.validation, ind , false);
        return ;
      }

      if(  (upload === null || upload === undefined || upload === "")  && (req === false || req === null || req === undefined) )
      {
        Vue.set(this.validation, ind , null);
        return ;
      }

      if(upload !== null && upload !== "" && upload !== undefined){
          Vue.set(this.validation, ind , true)
          return ;
      }
    },
  
    validate_text(ind, form, text){

      Vue.set(this.warning, ind , null);

      if( (text === null || text === "" || text === undefined) && form["required"] === true )
      {
        // console.log("--------- 1 ----------");
        Vue.set(this.validation, ind , false);
        Vue.set(this.warning, ind , "กรุณาใส่ข้อมูล");
        return ;
      }

      if( (text === null || text === "" || text === undefined) && (form["required"] === false || form["required"] === null || form["required"] === undefined) )
      {
        // console.log("--------- 2 ----------");
        Vue.set(this.validation, ind , null);
        return ;
      }

       if( text.length <= 0 && form["required"] === true )
       {
        //  console.log("--------- 3 ----------");
          Vue.set(this.validation, ind , false);
          Vue.set(this.warning, ind , null);
          return;
       }

        //*** ถ้ามีการกำหนด form["min"] */
        if(form["min"] != null && form["min"] != undefined){
            if(text.length < form["min"] ){
                // console.log("--------- 4 ----------");
                Vue.set(this.validation, ind , false);
                Vue.set(this.warning, ind , "ใส่ตัวอักษรไม่น้อยกว่า " +  form["min"]);
                return;
            }
        }

        //*** ถ้ามีการกำหนด form["max"] */
        if(form["max"] != null && form["max"] != undefined){
          //*** ถ้าความยาว text มากกว่า max */
          if(text.length > form["max"] ){
                // console.log("--------- 5 ----------");
                Vue.set(this.validation, ind , false);
                Vue.set(this.warning, ind , "ใส่ตัวอักษรไม่เกิน " +  form["max"]);
                return;
            }
        }


        if(form["type"] === "email"){
              let res = this.validate_email(text);
              if(res === true){
                Vue.set(this.validation, ind , true);
                return;
              }else{
                Vue.set(this.warning, ind , "ใส่ข้อมูลในรูปแบบตัวอักษร ตัวอย่าง user@example.com");
                Vue.set(this.validation, ind , false);
                return;
              }
        }


        // console.log("--------- 6 ----------");
        Vue.set(this.validation, ind , true);

    },

    validate_email(email_address){
        if(email_address === null || email_address === undefined )
          return null;
        if(email_address.length <= 0)
        {
          return false;
        }
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
        if (email_address.match(regexEmail)) {
          return true; 
        } else {
          return false; 
        }
      },

   
    validate_select(ind, req, value){


        if( (value === null || value === undefined || value === "" ) && req === true )
        {
          Vue.set(this.validation, ind , false)
          return  ;
        }

        if( (value === null || value === undefined || value === "" ) && (req === false || req === null || req === undefined) )
        {
          Vue.set(this.validation, ind , null)
          return  ;
        }

        if(value !== null && value !== "" && value !== undefined){
          Vue.set(this.validation, ind , true)
          return ;
        }
      },
      
   
  }
};
</script>
<style scoped>
.formSlidebar{
  width: 100%;
}

</style>